<template>
    <v-app id="inspire" style="background: #fafafa;">
        <app-bar-front-page></app-bar-front-page>
        <v-content>
            <app-loader></app-loader>
            <v-container class="front_page_container" grid-list-xl>
                <v-form id="form_parking_application" ref="form" v-model="valid" lazy-validation>
                    <v-layout row wrap align-center justify-center fill-height>
                    <v-flex xs12 sm12 md10 lg8>
                        <p class="title text-center"><v-icon large left>mdi-file-document-box-check-outline</v-icon> Elektronska oddaja vlog za izdajo dovolilnic</p>
                        <div class="layout column align-center">
                            <div>
                                <p class="title"> Občina Bled</p>
                            </div>
                            <img :src="getLogo" alt="Dovolilnice | Občina bled"  height="auto">
                            <br>
                        </div>
                    </v-flex>
                </v-layout>
                    <v-layout row wrap align-center justify-center fill-height>
                        <v-flex xs12 sm12 md10 lg8>
                            <v-card id="form_card">
                            <v-img
                            :src="imgParking"
                            height="500px"
                            ></v-img>
                                <v-card-title>
                                    <span class="subtitle-1 application--card--title text-center">
                                    <v-icon left>mdi-file-document-edit-outline</v-icon>
                                    Vloga za izdajo parkirne dovolilnice (Blejska kartica)</span>
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text>
                                    <!-- backend validation messages -->
                                    <form-validation-messages :messages="apiValidationMessages">
                                    </form-validation-messages>

                                    <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>

                                    <info-user-type-domacin ></info-user-type-domacin>
                                    <info-user-type-gost ></info-user-type-gost>
                                    <info-user-type-velika-zaka></info-user-type-velika-zaka>

                                    <!-- tip uporabnika -->
                                    <v-select
                                    :items="userTypes"
                                    label="Izberi tip Blejske kartice"
                                    item-text="name"
                                    item-value="id"
                                    v-model="user_type"
                                    :rules="user_type_rules"
                                    ></v-select>


                                    <!-- imetnik -->
                                    <v-text-field
                                        v-model="name"
                                        label="Ime in priimek"
                                        required
                                        :rules="name_rules"
                                        :disabled="!userTypeSelected"
                                    ></v-text-field>

                                    <!-- ulica -->
                                    <v-text-field
                                        v-model="address"
                                        label="Naslov (prebivališče)"
                                        required
                                        :rules="address_rules"
                                        :disabled="!userTypeSelected"
                                    ></v-text-field>

                                    <!-- kraj -->
                                    <v-text-field
                                        v-model="town"
                                        label="Kraj"
                                        required
                                        :rules="town_rules"
                                        :disabled="!userTypeSelected"
                                    ></v-text-field>

                                    <!-- poštna številka -->
                                    <v-text-field
                                        v-model="postal_code"
                                        label="Poštna številka"
                                        required
                                        :rules="postal_code_rules"
                                        type="number"
                                        :disabled="!userTypeSelected"
                                    ></v-text-field>

                                    <!-- izbira prebivališča -->
                                    <v-select
                                    :items="residenceTypes"
                                    label="Tip prebivališča"
                                    item-text="name"
                                    item-value="id"
                                    v-model="residence_type"
                                    :rules="residence_type_rules"
                                    :disabled="!userTypeSelected"
                                    ></v-select>

                                    <!-- telefonska številka -->
                                    <v-text-field
                                        v-model="phone_number"
                                        label="Telefonska številka"
                                        required
                                        :rules="telephone_number_rules"
                                        type="number"
                                        :disabled="!userTypeSelected"
                                    ></v-text-field>


                                    <!-- emšo 
                                    <v-text-field
                                        v-model="emso"
                                        label="EMŠO"
                                        required
                                        :rules="emso_rules"
                                        type="number"
                                        :disabled="!userTypeSelected"
                                    ></v-text-field>-->

                                    <!-- tip osebe (podjetje / fizična oseba) -->
                                     <v-select
                                    :items="entities"
                                    label="Izberi tip osebe"
                                    item-text="name"
                                    item-value="id"
                                    v-model="entity"
                                    :rules="entity_rules"
                                    :disabled="!userTypeSelected"
                                    ></v-select>
                                    
                                    <!-- davčna številka -->
                                    <v-text-field
                                        v-model="tax_number"
                                        label="Davčna številka"
                                        required
                                        :rules="tax_number_rules"
                                        v-if="requireTaxNumber"
                                        :disabled="!userTypeSelected"
                                    ></v-text-field>

                                    <!-- registrska številka -->
                                    <v-text-field
                                        v-model="registration_plate_number_1"
                                        label="Registrska številka vozila"
                                        :rules="registration_plate_number_rules"
                                        required
                                        :disabled="!userTypeSelected"
                                    ></v-text-field>

                                    <!-- vozilo v osebni lasti ali lasti podjetja -->
                                    <v-select
                                    :items="vehicleOwnershipTypes"
                                    label="Lastništvo vozila"
                                    item-text="name"
                                    item-value="id"
                                    v-model="vehicle_ownership"
                                    :rules="vehicle_ownership_rules"
                                    :disabled="!userTypeSelected"
                                    ></v-select>
                                    <!--<br>
                                    <v-divider></v-divider>-->
                                    <br>
                                    <p class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon> Priložite fotokopijo vašega prometnega dovoljenja. V primeru da je vozilo v lasti podjetja, morate priložiti potrdilo od podjetja. V primeru zamenjave registrske številke za uporabo dovolilnice je potrebno priložiti kopijo prometnega dovoljenja. V primeru zamenjave registrske številke za uporabo dovolilnice je potrebno priložiti kopijo prometnega dovoljenja.</p>
                                    <p v-if="temporaryResidence" class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon> Priložite fotokopijo dokazila o začasnem prebivališču.</p>
                                    <!-- dokumenti -->
                                    <VueFileAgent
                                    :uploadHeaders="{}"
                                    :multiple="true"
                                    :deletable="true"
                                    :meta="true"
                                    :accept="'image/*,.pdf'"
                                    :maxSize="'5MB'"
                                    :maxFiles="5"
                                    :helpText="'Dodaj datoteke...'"
                                    :errorText="{
                                        type: 'Dovoljen tip datotek - jpg, png, pdf do 3MB',
                                        size: 'Dodali ste datoteko, ki presega največjo dovoljeno velikost 3MB!',
                                    }"
                                    :thumbnailSize="120"
                                    :theme="'list'"
                                    v-model="files_data"
                                    ></VueFileAgent>

                                    <!--<br><div style="min-height:5px"></div>-->
                                    <!--<v-divider></v-divider>-->
                                   
                                    <!-- način prevzema / dostave  -->
                                </v-card-text>
                                <br><div style="min-height:5px"></div>
                                <v-divider></v-divider>
                                <v-app-bar
                                    flat
                                    color="grey lighten-4"
                                    dense
                                    class="elevation-0"
                                >   
                                    <v-icon left>mdi-account</v-icon>
                                    <v-toolbar-title class="body-2">Uporabniški račun</v-toolbar-title>
                                </v-app-bar>
                                <v-divider></v-divider>
                            <v-divider></v-divider>
                                <v-card-text>
                                    <p class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon> Vpišite elektronski naslov in geslo za dostop do uporabniškega računa. Po oddaji vloge se vam bo ustvaril uporabniški račun, s katerim se boste lahko prijavili v sistem za oddajo vlog in podaljševanje veljavnosti dovolilnic.</p>
                                    <!-- email -->
                                    <v-text-field
                                        v-model="email"
                                        label="E-mail"
                                        required
                                        :rules="email_rules"
                                        :disabled="!userTypeSelected"
                                    ></v-text-field>

                                    <v-text-field
                                    v-model="password"
                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="[password_rules.required, password_rules.min]"
                                    :type="show1 ? 'text' : 'password'"
                                    name="input-10-1"
                                    label="Vnesite geslo"
                                    hint=""
                                    counter
                                    @click:append="show1 = !show1"
                                    :disabled="!userTypeSelected"
                                ></v-text-field>

                                <v-text-field
                                    v-model="password_confirmation"
                                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="[password_rules.required, password_rules.min, passwordMatch]"
                                    :type="show2 ? 'text' : 'password'"
                                    name="input-10-1"
                                    label="Ponovno vpišite geslo"
                                    hint="Geslo naj vsebuje vsaj 8 znakov"
                                    counter
                                    @click:append="show2 = !show2"
                                    @keyup.enter="submitForm()"
                                    :disabled="!userTypeSelected"
                                ></v-text-field>
                                </v-card-text>
                                
                                <v-divider></v-divider>

                                <v-card-text>
                                    <v-checkbox :disabled="!userTypeSelected" @change="enableSubmission" class="" v-model="disclaimer" :label="disclaimer_text" :rules="disclaimer_rules"></v-checkbox>
                                    <div style="min-height:5px"></div>
                                    <!--<p class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon> Ob izdaji dovolilnice se vam bo ustvaril uporabniški račun, s katerim se boste lahko prijavili v sistem za oddajo vlog in podaljševanje veljavnosti dovolilnic.</p>-->
                                    <p class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon>Vpisani osebni podatki bodo prvič poslani v obdelavo šele v koraku "Oddaj vlogo". Več o obdelavi in hranjenju osebnih podatkov si lahko preberete s klikom <a @click="infoGdpr()">TUKAJ</a>.</p>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                                    <v-btn @click="submitForm()" color="success" block outlined style="margin-bottom:10px;" :disabled="loading ||!userTypeSelected"><v-icon left>mdi-arrow-right</v-icon>Oddaj vlogo</v-btn>
                                    <v-btn @click="clearForm()" color="error" block outlined style="margin-bottom:10px;"><v-icon left>mdi-close</v-icon>Prekliči</v-btn>
                                </v-card-text>
                                <v-card-actions v-else>
                                    <v-btn @click="submitForm()" color="success" text :disabled="loading ||!userTypeSelected"><v-icon left>mdi-arrow-right</v-icon>Oddaj vlogo</v-btn>
                                    <v-btn @click="clearForm()" color="error" text><v-icon left>mdi-close</v-icon>Prekliči</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-container>
        </v-content>
        <app-fab></app-fab>
    </v-app>
</template>

<script>
import AppFab from '@/components/layout/AppFab'
import router from '@/router'
import  { Role } from '@/helpers/role'
import AppBarFrontPage from '@/components/layout/AppBarFrontPage'
import AppToolbar from '@/components/layout/AppToolbar'
import imgCardParking from '@/assets/card_blejska_kartica.png'
import { API_ENDPOINT } from '@/helpers/api'
import axios from 'axios'
import logo from '@/assets/grb_bled_135_135.png'
import { getUserRole } from '@/helpers/utilities'
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')
const InfoUserTypeDomacin = () => import('@/components/InfoUserTypeDomacin.vue')
const InfoUserTypeGost = () => import('@/components/InfoUserTypeGost.vue')
const InfoUserTypeVelikaZaka = () => import('@/components/InfoUserTypeVelikaZaka.vue')

export default {
    components: {
        AppFab,
        AppBarFrontPage,
        FormValidationMessages,
        FormSubmitMessages,
        InfoUserTypeDomacin,
        InfoUserTypeGost,
        InfoUserTypeVelikaZaka
    },

    data: () => ({
        valid: true,
        user_type: null,
        name: null,
        address: null,
        town: null,
        postal_code: null,
        residence_type: null,
        temporary_residence_address: null,
        telephone_number: null,
        tax_number: null,
        subject_type: null,
        email: null,
        phone_number: null,
        emso: null,
        entity: null,
        registration_plate_number_1: null,
        vehicle_ownership: null,
        delivery_type: null,
        disclaimer: false,
        disclaimer_text: 'S potrditvijo vloge izjavljam, da so vsi podatki, ki sem jih navedel v vlogi, resnični, točni in popolni in da za svojo izjavo prevzemam vso materialno in kazensko odgovornost. Organ lahko odvzame pravico do dovolilnice če se izkaže, da podatki v vlogi niso resnični oziroma, da upravičenec več ne izpolnjuje pogojev za dovolilnico.',
        enable_submission: false,
        files_data: [],
        password: '',
        password_confirmation: '',
        

        userTypes: [],
        residenceTypes: [],
        entities: [],
        vehicleOwnershipTypes: [],
        userTypeSelected: false,

        user_type_rules: [v => !!v || 'Polje tip uporabnika je obvezno'],
        name_rules: [v => !!v || 'Polje ime in priimek je obvezno'],
        address_rules: [v => !!v || 'Polje naslov prebivališča je obvezno'],
        town_rules: [v => !!v || 'Polje kraj je obvezno'],
        postal_code_rules: [v => !!v || 'Polje poštna številka je obvezno'],
        residence_type_rules: [v => !!v || 'Polje tip prebivališča je obvezno'],
        telephone_number_rules: [v => !!v || 'Polje telefonska številka je obvezno'],
        email_rules: [
            v => !!v || 'Polje e-mail je obvezno',
            v => /.+@.+\..+/.test(v) || 'Vnesen elektronski naslov ni pravilen'
        ],
        emso_rules: [v => !!v || 'Polje emso je obvezno'],
        tax_number_rules: [v => !!v || 'Polje davčna številka je obvezno'],
        entity_rules: [v => !!v || 'Polje tip osebe je obvezno'],
        registration_plate_number_rules: [
            v => !!v || 'Polje registrska številka vozila je obvezno',
           // v => ^[a-zA-Z0-9]+$.test(v) || 'Registrska številka lahko vsebuje le številke in črke. Ostali znaki in presledki niso dovoljeni!'
        ],
        vehicle_ownership_rules: [v => !!v || 'Polje lastništvo vozila je obvezno'],
        disclaimer_rules: [v => !!v || 'Strinjati se morate s pogoji'],
        password_rules: {
            required: value => !!value || 'Vnos gesla je obvezen',
            min: v => v.length >= 8 || 'Geslo mora vsebovati vsaj 8 znakov (uporabite številke, znake ter velike in male črke)'
        },
        loading:false,

        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],

        show1: false,
        show2: false

    }),

    watch: {
        user_type: {
            handler(newValue) {
                if(newValue !== null && newValue !== '' && newValue != undefined) {
                    this.userTypeSelected = true
                } else {
                    this.userTypeSelected = false
                }

                this.filterResidenceTypes()
            },

            immediate: true
        },

        registration_plate_number_1(value) {
            if(value != null) {
                //window.console.log(this.registration_plate_number_1)
                this.registration_plate_number_1 = this.registration_plate_number_1.replace(/[^a-zA-Z0-9]/g, "");
                this.registration_plate_number_1 = this.registration_plate_number_1.toUpperCase();
            }
        },

        residence_type(value) {
            window.console.log("Seleted residence type: ", value)
        }
    },

    computed: {
        getLogo() {
            return logo
        },

        requireTaxNumber() {
            if(this.entity == "74c968fc-f8f8-4596-b1f4-3616b4c2c029") {
                return true
            }

            return false
        },

        temporaryResidence() {
            if(this.residence_type == "045189aa-656c-4284-8e8d-b91611e896bf") {
                return true
            }

            return false
        },

        getUserTypeType() {
            if(this.user_type == '2f1448a6-a5ba-49fe-960a-d95461f9efe0') {
                return 1;
            }

            return 2;
        },

        imgParking() {
            return imgCardParking
        },

        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        apiValidationMessages() {
            return this.backendValidationMessages
        }
    },

    methods: {
        infoGdpr() {
            this.$store.commit('SET_INFO_GDPR', true)
        },

        enableSubmission() {
            if(this.disclaimer) {
                this.enable_submission = true;
            }

            this.enable_submission = false;
        },

        submitForm() {
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
            let vm = this
            if(this.$refs.form.validate()) {
                /*let one  = 1;
                if(1 == one){*/
                vm.$store.commit('SET_LOADER_TEXT', 'Oddaja vloge je v teku...')
                vm.$store.commit('SET_LOADER', true)
                let payload = new FormData();
                payload.append('user_type', vm.user_type)
                payload.append('name', vm.name)
                payload.append('address', vm.address)
                payload.append('town', vm.town)
                payload.append('postal_code', vm.postal_code)
                payload.append('residence_type', vm.residence_type)
                payload.append('phone_number', vm.phone_number)
                payload.append('email', vm.email)
                payload.append('emso', vm.emso)
                payload.append('tax_number', vm.tax_number)
                payload.append('entity', vm.entity)
                payload.append('registration_plate_number_1', vm.registration_plate_number_1)
                payload.append('vehicle_ownership', vm.vehicle_ownership)  
                payload.append('password', vm.password)

                let c = 1;
                vm.files_data.map(file => {
                    payload.append('file_' + c, file.file)
                    c++
                });
                
                //window.console.log(payload)
            vm.loading = true
            axios({
                method: 'POST',
                url: API_ENDPOINT + 'v1/applications/parking/submit',
                data: payload,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                //window.console.log(response)
                vm.clearForm()
                vm.backendMessages.push('Vloga je bila uspešno oddana. Vaša vloga bo obravana v najkrajšem možnem času. O nadaljnjem poteku v zvezi z izdajo dovolilnice vas bomo obvestili. V vaš profil se lahko prijavite z vašim e-naslovom in geslom.')
                    vm.$vuetify.goTo('#form_card', {
                    duration: 1000,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
            })
            .catch(error => {
                window.console.log(error)
                window.console.log(error.response)

                if(error.response.status === 422) {
                    //window.console.log(error.response.data.errors)

                    for (const property in error.response.data.errors) {
                        //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                        var propertyData = error.response.data.errors[property];
                        //window.console.log(propertyData);
                        propertyData.forEach(message => {
                            vm.backendValidationMessages.push(message);
                        })

                        vm.$vuetify.goTo('#form_card', {
                            duration: 1000,
                            offset: 0,
                            easing: 'easeInOutCubic'
                        })
                    }
                } else {
                    vm.backendErrorMessages.push('Ups... pri pošiljanju vloge je prišlo do napake. Poskusite ponovno.')
                    vm.$vuetify.goTo('#form_card', {
                        duration: 1000,
                        offset: 0,
                        easing: 'easeInOutCubic'
                    })
                }

            })
            .then(() => {
                setTimeout(() => {
                     vm.$store.commit('SET_LOADER', false)
                     vm.$store.commit('SET_LOADER_TEXT', '')
                }, 600)
                vm.loading = false
                //window.console.log("finally! :)");
            })


            } 
            else {
                this.$vuetify.goTo('#form_card', {
                    duration: 1000,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
                //window.console.log("Validation ERROR");
            }
            
        },

        clearForm() {
            //this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.user_type = null
            this.name = null
            this.address = null
            this.town = null
            this.postal_code = null
            this.residence_type = null
            this.phone_number = null
            this.email = null
            this.emso = null
            this.entity = null
            this.registration_plate_number_1 = null
            this.vehicle_ownership = null
            this.password = ''
            this.password_confirmation = ''
            this.files_data = [];
            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
            this.disclaimer = false
            setTimeout(() =>{
                this.$vuetify.goTo('#form_card', {
                    duration: 1000,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
            }, 200)
        },

        passwordMatch() {
            if(this.password !== this.password_confirmation) {
                return "Gesli se ne ujemata"
            }

            return true
        },

       filterResidenceTypes() {
            let resTypes = this.$store.getters.residenceTypes
            //window.console.log("resTypes")
            //window.console.log(resTypes)
            this.residence_type = null

            if(this.user_type !== null && resTypes !== null) {
                if(this.user_type == "9767630f-7f4f-46d9-9a64-484677c0ce03") {
                this.residenceTypes = [];
                resTypes.forEach((item) => {
                    if(item.type == 1 || item.type == 2 ) {
                        this.residenceTypes.push(item)
                    }
                })        
            } else {
                    //this.residenceTypes = resTypes;
                    this.residenceTypes = [];
                    resTypes.forEach((item) => {
                        if(item.type == 1 ) {
                            this.residenceTypes.push(item)
                        }
                    })
                }
            }
        }
    },

    created() {
        let vm = this
        this.$store.dispatch('RESOURCES')
        .then(response => {
            //window.console.log("### ParkingApplication@created ###")
            //window.console.log(response.data)

            if(response.data.hasOwnProperty('user_types')) {
                vm.$store.commit('SET_USER_TYPES', response.data.user_types)
            }

            if(response.data.hasOwnProperty('permit_types')) {
                vm.$store.commit('SET_PERMIT_TYPES', response.data.permit_types)
            }

            if(response.data.hasOwnProperty('residence_types')) {
                vm.$store.commit('SET_RESIDENCE_TYPES', response.data.residence_types)
            }

            if(response.data.hasOwnProperty('entities')) {
                vm.$store.commit('SET_ENTITIES', response.data.entities)
            }

            if(response.data.hasOwnProperty('vehicle_ownership_types')) {
                vm.$store.commit('SET_VEHICLE_OWNERSHIP_TYPES', response.data.vehicle_ownership_types)
            }
        })
        .catch(error => {
            window.console.error(error)
        })
        .finally(() =>{
            
            if(vm.$store.getters.userTypes != null) {
                vm.$store.getters.userTypes.forEach(element => {
                    if(element.hasOwnProperty('type')) {
                        if(element.type == 1 || element.type == 2 || element.type == 23) {
                            vm.userTypes.push(element)
                        }
                    }
                })
            }

            vm.residenceTypes = vm.$store.getters.residenceTypes
            vm.entities = vm.$store.getters.entities
            vm.vehicleOwnershipTypes = vm.$store.getters.vehicleOwnershipTypes

            //window.console.log(vm.residenceTypes);
        }) 
        
    },

    mounted() {
        if(getUserRole() === Role.User) {
            this.$router.push({
                name: 'user_parking_application'
            })
        }
    }
}
</script>

<style scoped>
    #inspire{
        background-image: url("../../assets/bckpattern.png") !important;
        background-repeat: repeat !important;
    }

    .front_page_container {
        margin-top:30px;
        margin-bottom:50px;
    }

    

    .card--text {
        color: rgb(0,0,0,0.8) !important;
    }

    .card--text--info {
        color: rgb(25, 118, 210, 0.8) !important;
    }

    .p--icon {
        margin-bottom: 2px !important;
    }

    .title {
        font-size:1.50rem !important;
    }

    .card--text--btn {
        opacity: 0.85
    }

    .application--card--title{
      font-size: 1.2rem !important;
    }
</style>